import * as React from 'react';
import Select from 'components/molecules/Select';
import { getEnum } from 'network/Api/Generic';
const { useState, useEffect } = React;

export default function SelectPais({ label, isDepartamento = false, ...rest }) {
  const [options, setOptions] = useState([
    { label: 'ANTIOQUIA_MEDELLIN', value: 'ANTIOQUIA_MEDELLIN' },
  ]);

  useEffect(() => {
    getEnum(isDepartamento ? 'DEPARTAMENTO' : 'MUNICIPIO').then(({ data }) => setOptions(data));
  }, []);

  return <Select label={label} options={options} {...rest} />;
}

import * as React from 'react';
import Select from 'components/molecules/Select';
import { getEnum } from 'network/Api/Generic';
const { useState, useEffect } = React;

export default function SelectSexo({ label, ...rest }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getEnum('CODIGO_SEXO').then(({ data }) => setOptions(data));
  }, []);

  return <Select label={label} options={options} {...rest} />;
}

import * as React from 'react';
import Select from 'components/molecules/Select';
import { getEnum } from 'network/Api/Generic';
const { useEffect, useState, useCallback } = React;

export default function SelectCausaMotivoAtencion({ ...props }) {
  const [options, setOptions] = useState([]);

  const consultarOptions = useCallback(async () => {
    const { data } = await getEnum('CAUSA_MOTIVO_ATENCION');
    setOptions(data);
  });

  useEffect(() => {
    consultarOptions();
  }, []);

  return <Select options={options} {...props} />;
}

import * as React from 'react';
import { connect } from 'react-redux';
import UsuarioForm from 'components/templates/RipsUsuarioForm';
import useData from 'hooks/useData';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import Select from 'components/molecules/Select';
import useModal from 'hooks/useModal';
import Modal from 'components/molecules/Modal';
import Table from 'components/molecules/Table';
import {
  enviarRips,
  consultarDocumentosRips,
  consultarDocumentoRip,
  guardarDocumentoRips,
} from 'network/Api/Rips';
import Delete from '@material-ui/icons/DeleteForever';
const { useCallback, useEffect, useMemo, useState } = React;

function EnvioRips({ userAttributes }) {
  const { setState, state: rips, handleChange, handleChangeSelect } = useData();
  const [modalProps, openModal] = useModal();
  const [docs, setDocs] = useState([]);

  const addUsuario = useCallback(
    (newUsuario) => {
      const { usuarios = [] } = rips;
      let nuevosUsuarios = usuarios.map((u, id) => ({ ...u, id }));
      nuevosUsuarios.push({ ...newUsuario, id: nuevosUsuarios.length });
      setState({ usuarios: nuevosUsuarios });
    },
    [rips],
  );

  useEffect(() => {
    consultarDocumentosRips().then((r) => {
      const { data = [] } = r;
      const documentos = data.map((doc) => ({ label: doc.replace('-', ''), value: doc }));
      setDocs(documentos);
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.size > 0) {
        const dsPrefijo = urlParams.get('prefijo');
        const dsNumero = urlParams.get('numero');
        setState({ dsPrefijo, dsNumero, documento: `${dsPrefijo}-${dsNumero}` });
        consultarRips(dsPrefijo, dsNumero);
      }
    });
  }, []);

  useEffect(() => {
    setState({ numDocumentoIdObligado: userAttributes['custom:custom:userid'] });
  }, [userAttributes]);

  const enviar = useCallback(() => {
    const { codigoEmisor } = userAttributes;
    const { clave, documento } = rips;
    const body = { emisorId: codigoEmisor, rips, clave };
    if (clave && documento) {
      enviarRips(body);
      openModal({
        content: 'Enviando rips...',
        onCancel: () => window.location.reload(),
        onAccept: () => window.location.reload(),
      });
    } else {
      openModal({ content: 'El documento y la clave son obligatorios' });
    }
  }, [rips, userAttributes]);

  const guardar = useCallback(() => {
    const { codigoEmisor } = userAttributes;
    const body = { emisorId: codigoEmisor, rips };
    guardarDocumentoRips(body);
    openModal({
      content: 'Guardando rips...',
      onCancel: () => window.location.reload(),
      onAccept: () => window.location.reload(),
    });
  }, [rips, userAttributes]);

  const deleteButton = useCallback(
    (id) => {
      const { usuarios = [] } = rips;
      setState({ usuarios: usuarios.filter((u) => u.id !== id).map((u, id) => ({ ...u, id })) });
    },
    [rips],
  );

  const tableDetails = useMemo(() => {
    const { usuarios = [] } = rips;
    return usuarios.map((usuario) => ({
      usuario: usuario.numDocumentoIdentificacion,
      actions: (
        <Button onClick={() => deleteButton(usuario.id)}>
          <Delete />
        </Button>
      ),
    }));
  }, [rips, deleteButton]);

  const handleChangeDocumento = useCallback(
    async (event) => {
      const { value } = event;
      const [prefijo, numero] = value.split('-');
      setState({ prefijo, numero });
      handleChangeSelect(event);
      consultarRips(prefijo, numero);
    },
    [userAttributes],
  );

  const consultarRips = useCallback(async (prefijo, numero) => {
    const { data } = await consultarDocumentoRip({ prefijo, numero });
    setState({ ...data });
  }, []);

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className='form-row col-12 mb-3'>
          <Input
            label='NIT del facturador'
            name='numDocumentoIdObligado'
            value={rips.numDocumentoIdObligado}
            onChange={handleChange}
            className='col-md-2'
            required
            disabled
          />
          <Select
            label='Documento'
            name='documento'
            className='col-md-3'
            onChange={handleChangeDocumento}
            options={docs}
            value={rips.documento}
            required
          />
          <Input
            label='Clave Sispro del medico facturador'
            name='clave'
            value={rips.clave}
            onChange={handleChange}
            className='col-md-2'
            required
          />
        </div>
        <UsuarioForm addUsuario={addUsuario} openModal={openModal} />
      </form>
      <Table
        withPagination={false}
        headers={[{ label: 'Usuario' }, { label: 'Acciones' }]}
        keys={['usuario', 'actions']}
        detalles={tableDetails}
        cellStyle={{ textAlign: 'center' }}
      />
      <Button style={{ margin: '20px 5px' }} onClick={enviar}>
        Enviar RIPS
      </Button>
      <Button style={{ margin: '20px 5px' }} onClick={guardar}>
        Guardar RIPS
      </Button>
      <Modal {...modalProps} />
    </>
  );
}

const mapStateToProps = (state) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EnvioRips);

import * as React from 'react';
import ConsultaForm from 'components/templates/RipsServicioConsultaForm';
import ProcedimientoForm from 'components/templates/RipsServicioProcedimientoForm';
import HospitalizacionForm from 'components/templates/RipsServicioHospitalizacionForm';
import UrgenciasForm from 'components/templates/RipsServicioUrgenciaForm';
import RecienNacidosForm from 'components/templates/RipsServicioRecienNacidoForm';
import MedicamentosForm from 'components/templates/RipsServiciosMedicamentosForm';
import OtrosServiciosForm from 'components/templates/RipsServicioOtroServicioForm';
import ExpansionPanel from 'components/atoms/ExpansionPanel';
import Input from 'components/molecules/Input';
import useData from 'hooks/useData';
import SelectTipoIdentificacion from 'components/organisms/SelectTipoIdentificacion';
import SelectSexo from 'components/organisms/SelectSexo';
import SelectZonaTerritorial from 'components/organisms/SelectZonaTerritorial';
import SelectTipoUsuario from 'components/organisms/SelectTipoUsuario';
import SelectPais from 'components/organisms/SelectPais';
import SelectMunicipio from 'components/organisms/SelectMunicipio';
import Button from 'components/atoms/Button';
import Table from 'components/molecules/Table';
import Typography from 'components/atoms/Typography';
import Delete from '@material-ui/icons/DeleteForever';
import { now } from 'utils/DateUtils';
const { useCallback, useMemo } = React;

const defaultValues = {
  codPaisOrigen: 'COLOMBIA',
  codPaisResidencia: 'COLOMBIA',
  codMunicipioResidencia: 'ANTIOQUIA_MEDELLIN',
  tipoDocumentoIdentificacion: 'CEDULA_CIUDADANIA',
};

export default function RipsUsuarioForm({ addUsuario, openModal }) {
  const { handleChange, handleChangeSelect, setState, state, setData } = useData(defaultValues);

  const addServicio = useCallback(
    (newServicio, name) => {
      const { servicios = {} } = state;
      const serviceName = servicios[name] || [];
      const serviciosActualizados = [...serviceName, { ...newServicio, id: serviceName.length }];
      setState({ servicios: { ...servicios, [name]: serviciosActualizados } });
    },
    [state],
  );

  const handleAddUsuario = useCallback(() => {
    const errores = validateUser(state);
    if (errores.length === 0) {
      addUsuario(state);
      setData({});
    } else openModal({ content: `Error de validaciones de usuario: ${errores.join(' - ')}` });
  }, [addUsuario, state]);

  const validateUser = useCallback((user) => {
    let errors = [];
    if (!user.tipoDocumentoIdentificacion) errors.push('El tipo de identificacion es obligatoria');
    if (!user.numDocumentoIdentificacion) errors.push('La identificacion es obligatoria');
    if (!user.tipoUsuario) errors.push('El tipo de usuario es obligatorio');
    if (!user.codSexo) errors.push('El codigo sexo es obligatorio');
    if (!user.fechaNacimiento) errors.push('La fecha de nacimiento es obligatoria');
    if (!user.codPaisOrigen) errors.push('El pais de origen es obligatorio');
    if (!user.codPaisResidencia) errors.push('El pais de residencia es obligatorio');
    if (!user.codMunicipioResidencia) errors.push('El municipio de residencia es obligatorio');
    if (!user.codZonaTerritorialResidencia) errors.push('La zona territorial es obligatoria');
    if (!user.incapacidad) errors.push('El identificador de incapacidad es obligatorio');
    if (!user.consecutivo) errors.push('El consecutivo es obligatorio');
    if (Object.keys(user.servicios).length === 0)
      errors.push('Es obligatorio agregar algun servicio');
    return errors;
  }, []);

  const tableDetails = useMemo(() => {
    const serviciosDet = [];
    const { servicios = {} } = state;
    const {
      consultas = [],
      procedimientos = [],
      hospitalizacion = [],
      urgencias = [],
      recienNacidos = [],
      medicamentos = [],
      otrosServicios = [],
    } = servicios;
    const add = (servicio, id) =>
      serviciosDet.push({
        servicio,
        id,
        acciones: (
          <Button onClick={() => deleteServicio(id)}>
            <Delete />
          </Button>
        ),
      });
    consultas.forEach(({ id }) => add('consulta', `consultas-${id}`));
    procedimientos.forEach(({ id }) => add('procedimiento', `procedimientos-${id}`));
    hospitalizacion.forEach(({ id }) => add('hospitalizacion', `hospitalizacion-${id}`));
    urgencias.forEach(({ id }) => add('urgencia', `urgencias-${id}`));
    recienNacidos.forEach(({ id }) => add('recien nacido', `recienNacidos-${id}`));
    medicamentos.forEach(({ id }) => add('medicamento', `medicamentos-${id}`));
    otrosServicios.forEach(({ id }) => add('otro servicio', `otrosServicios-${id}`));
    return serviciosDet;
  }, [state.servicios]);

  const deleteServicio = useCallback(
    (id) => {
      const [serviceName, serviceId] = id.split('-');
      const { servicios } = state;
      const currentServicios = servicios[serviceName] || [];
      const nuevosServicios = currentServicios
        .filter(({ id }) => id !== parseInt(serviceId))
        .map((s, id) => ({ ...s, id }));
      setState({ servicios: { ...servicios, [serviceName]: nuevosServicios } });
    },
    [state.servicios],
  );

  return (
    <>
      <Typography
        style={{ textAlign: 'center', display: 'block' }}
        component='h3'
        variant='labelInput'
      >
        Agregar servicios
      </Typography>
      <ExpansionPanel title='Agregar Consulta'>
        <ConsultaForm addServicio={addServicio} openModal={openModal} />
      </ExpansionPanel>
      <ExpansionPanel title='Agregar Procedimiento'>
        <ProcedimientoForm addServicio={addServicio} openModal={openModal} />
      </ExpansionPanel>
      <ExpansionPanel title='Agregar Hospitalización'>
        <HospitalizacionForm addServicio={addServicio} openModal={openModal} />
      </ExpansionPanel>
      <ExpansionPanel title='Agregar Urgencias'>
        <UrgenciasForm addServicio={addServicio} openModal={openModal} />
      </ExpansionPanel>
      <ExpansionPanel title='Agregar Recién Nacidos'>
        <RecienNacidosForm addServicio={addServicio} openModal={openModal} />
      </ExpansionPanel>
      <ExpansionPanel title='Agregar Medicamentos'>
        <MedicamentosForm addServicio={addServicio} openModal={openModal} />
      </ExpansionPanel>
      <ExpansionPanel title='Agregar Otros Servicios'>
        <OtrosServiciosForm addServicio={addServicio} openModal={openModal} />
      </ExpansionPanel>
      <Typography
        style={{ textAlign: 'center', display: 'block', padding: '10px' }}
        component='h3'
        variant='labelInput'
      >
        Servicios agregados
      </Typography>
      <div style={{ marginBottom: '30px' }}>
        <Table
          headers={[{ label: 'Servicio' }, { label: 'Acciones' }]}
          keys={['servicio', 'acciones']}
          detalles={tableDetails}
          cellStyle={{ textAlign: 'center' }}
        />
      </div>

      <Typography
        style={{ textAlign: 'center', display: 'block' }}
        component='h3'
        variant='labelInput'
      >
        Agregar nuevo usuario
      </Typography>
      <div className='form-group'>
        <div className='form-row col-12'>
          <SelectTipoIdentificacion
            label='Tipo de identificación'
            name='tipoDocumentoIdentificacion'
            value={state.tipoDocumentoIdentificacion}
            onChange={handleChangeSelect}
            className='col-md-2'
            require
          />
          <Input
            label='Identificación'
            name='numDocumentoIdentificacion'
            value={state.numDocumentoIdentificacion}
            onChange={handleChange}
            className='col-md-3'
            require
          />
          <SelectTipoUsuario
            label='Tipo de usuario'
            name='tipoUsuario'
            value={state.tipoUsuario}
            onChange={handleChangeSelect}
            className='col-md-2'
            require
          />
          <SelectSexo
            label='Sexo'
            name='codSexo'
            value={state.codSexo}
            className='col-md-2'
            onChange={handleChangeSelect}
            require
          />
          <Input
            label='Fecha de nacimiento'
            type='date'
            name='fechaNacimiento'
            value={state.fechaNacimiento}
            className='col-md-3'
            max={now().format('YYYY-MM-DDTHH:mm')}
            onChange={handleChange}
            require
          />
          <SelectPais
            label='País de origen'
            name='codPaisOrigen'
            value={state.codPaisOrigen}
            onChange={handleChangeSelect}
            className='col-md-3'
            require
          />
          <SelectPais
            label='País de residencia'
            name='codPaisResidencia'
            value={state.codPaisResidencia}
            onChange={handleChangeSelect}
            className='col-md-3'
            require
          />
          <SelectMunicipio
            label='Municipio de residencia'
            name='codMunicipioResidencia'
            value={state.codMunicipioResidencia}
            onChange={handleChangeSelect}
            className='col-md-2'
            isDepartamento={true}
            require
          />
          <SelectZonaTerritorial
            label='Zona territorial'
            name='codZonaTerritorialResidencia'
            value={state.codZonaTerritorialResidencia}
            onChange={handleChangeSelect}
            className='col-md-2'
            require
          />
          <Input
            label='Identificador de incapacidad'
            name='incapacidad'
            value={state.incapacidad}
            className='col-md-2'
            onChange={handleChange}
            require
          />
          <Input
            label='Consecutivo'
            name='consecutivo'
            value={state.consecutivo}
            className='col-md-2'
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className='form-row col-12 mt-3'>
        <Button onClick={handleAddUsuario} variant='contained'>
          Agregar usuario
        </Button>
      </div>
    </>
  );
}

import * as React from 'react';
import { connect } from 'react-redux';
import Table from 'components/molecules/Table';
import { consultarRips } from 'network/Api/Rips';
import Input from 'components/molecules/Input';
import Button from 'components/atoms/Button';
import { now } from 'utils/DateUtils';
import { Link } from 'react-router-dom';

function getStringState(estado = '') {
  switch (estado.toUpperCase()) {
    case 'CREADO':
      return 'Guardado';
    case 'ERROR':
      return 'Error';
    case '':
      return 'No iniciado';
    case 'EXITOSO':
      return 'Exitoso';
    case 'EXCEPCION_ENVIO':
      return 'Error desconocido';
    default:
      return 'Desconocido';
  }
}

function ConsultaRips({ userAttributes }) {
  const [data, setData] = React.useState([]);
  const [inicio, setInicio] = React.useState(now().subtract(1, 'month').format('YYYY-MM-DD'));
  const [fin, setFin] = React.useState(now().format('YYYY-MM-DD'));
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const { codigoEmisor } = userAttributes;
    if (codigoEmisor) handleConsultar();
  }, [userAttributes.codigoEmisor]);

  const handleConsultar = React.useCallback(async () => {
    setLoading(true);
    const { codigoEmisor: id } = userAttributes;
    const { data } = await consultarRips({ id, inicio, fin });
    setData(data);
    setLoading(false);
  }, [userAttributes.codigoEmisor, inicio, fin]);

  // const anularRips = React.useCallback((rips) => {
  //   const { dsJson } = rips;
  //   const json = JSON.parse(dsJson).rips;
  //   console.log({ ...json, numNota: json.numero, tipoNota: 'NOTA_CREDITO' });
  // }, []);

  const buildAccionButton = React.useCallback((rips) => {
    const { cdEstado = '', dsPrefijo = '', dsNumero = '' } = rips;
    const estado = cdEstado.toUpperCase();
    if (estado === '')
      return (
        <Link to={`/rips?prefijo=${dsPrefijo}&numero=${dsNumero}`}>
          <Button>Iniciar RIPS</Button>
        </Link>
      );
    // if (cdEstado.toUpperCase() === 'EXITOSO')
    //   return <Button onClick={() => anularRips(rips)}>Anular</Button>;
    if (estado === 'CREADO')
      return (
        <Link to={`/rips?prefijo=${dsPrefijo}&numero=${dsNumero}`}>
          <Button>Enviar RIPS</Button>
        </Link>
      );
    if (estado === 'ERROR')
      return (
        <Link to={`/rips?prefijo=${dsPrefijo}&numero=${dsNumero}`}>
          <Button>Reenviar RIPS</Button>
        </Link>
      );
    return <></>;
  }, []);

  const dataTable = React.useMemo(() => {
    if (!loading) return [];
    return data.map((d) => {
      const { dsJson = '{}', cdEstado = '', dsJsonRespuesta } = d;
      const { rips = {} } = JSON.parse(dsJson);
      const [usuario = {}] = rips.usuarios || [];
      const errores = [getStringState(cdEstado)];
      if (cdEstado.toUpperCase() === 'ERROR' && dsJsonRespuesta) {
        const estado = JSON.parse(dsJsonRespuesta).estado;
        const descripciones = estado.descripcion;
        const estadoErrores = estado.errores;
        const typeDesc = typeof descripciones;
        if (descripciones && typeDesc === 'object') errores.push(...descripciones);
        if (descripciones && typeDesc === 'string') errores.push(descripciones);
        if (estadoErrores) errores.push(...estadoErrores);
      }
      return {
        ...d,
        cdEstado: errores.join(' - '),
        prefijoNumero: d.dsPrefijo + d.dsNumero,
        dsIdentificacion: usuario.numDocumentoIdentificacion,
        btnAccion: buildAccionButton(d),
      };
    });
  }, [data]);

  return (
    <div>
      <div className='form-group'>
        <div className='form-row col-12 d-flex align-items-end'>
          <Input
            className='col-2'
            label='Fecha inicio'
            name='inicio'
            value={inicio}
            type='date'
            onChange={({ target }) => setInicio(target.value)}
            min={now().subtract(1, 'month').format('YYYY-MM-DD')}
            max={fin}
          />
          <Input
            className='col-2'
            label='Fecha fin'
            name='fin'
            value={fin}
            min={inicio}
            type='date'
            onChange={({ target }) => setFin(target.value)}
            max={now().format('YYYY-MM-DD')}
          />
          <div className='col-3'>
            <Button onClick={handleConsultar}>Consultar</Button>
          </div>
        </div>
      </div>
      <Table
        loading={loading}
        headers={[
          { label: 'Número de factura' },
          { label: 'Identificación del paciente' },
          { label: 'Estado' },
          { label: 'Fecha del último estado' },
          { label: 'Accón' },
        ]}
        keys={['prefijoNumero', 'dsIdentificacion', 'cdEstado', 'feProceso', 'btnAccion']}
        detalles={dataTable}
        cellStyle={{ textAlign: 'center' }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaRips);
